import Cookies from 'js-cookie';
import {initDropdowns} from '/src/site/base/dropdown';
import {
    ADDITIONAL_COOKIE_PREFIX,
    COOKIE_POLICY_CONFIG,
    PAGE_TO_RETURN_NAME,
    sendCookiesUpdatedEvent,
    setRootCookies,
} from '/src/components/b2c/cookie/cookie-commons';

const BUTTON_TYPE_ACCEPT_ALL = 'accept-all';
const BUTTON_TYPE_CHANGED = 'changed';

function showButton(preferences, buttonType) {
    const saveChangesButton = document.querySelector('.js-accept-custom-cookies', preferences);
    const acceptAllButton = document.querySelector('.js-accept-all-cookies', preferences);

    if (buttonType === BUTTON_TYPE_ACCEPT_ALL) {
        acceptAllButton.classList.remove('hidden');
        acceptAllButton.closest('.has-button').classList.remove('hidden');
        saveChangesButton.classList.add('hidden');
        saveChangesButton.closest('.has-button').classList.add('hidden');
    } else {
        acceptAllButton.classList.add('hidden');
        acceptAllButton.closest('.has-button').classList.add('hidden');
        saveChangesButton.classList.remove('hidden');
        saveChangesButton.closest('.has-button').classList.remove('hidden');
    }
}

function toggleAcceptButton(preferences, event) {
    const cookieCheckboxes = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];
    const allDisabled = cookieCheckboxes.every((cookie) => cookie.checked === false);
    const allEnabled = cookieCheckboxes.every((cookie) => cookie.checked === true);
    const saveChangesClicked = event?.target.classList.contains('js-accept-custom-cookies');

    if (!allEnabled && saveChangesClicked) {
        showButton(preferences, BUTTON_TYPE_ACCEPT_ALL);

        return;
    }

    if (allDisabled) {
        showButton(preferences, BUTTON_TYPE_ACCEPT_ALL);
    } else {
        showButton(preferences, BUTTON_TYPE_CHANGED);
    }
}

function applyCurrentCookies(preferences) {
    const currentStatus = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];
    const previousCookieValues = {};
    setRootCookies();

    currentStatus.forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const currentCookieId = `${ADDITIONAL_COOKIE_PREFIX + cookieId}`;
        previousCookieValues[currentCookieId] = Cookies.get(currentCookieId);
        cookieId && Cookies.set(currentCookieId, cookie.checked, COOKIE_POLICY_CONFIG);
    });

    sendCookiesUpdatedEvent(previousCookieValues);
}

function acceptAllCookies(preferences) {
    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookie) => {
        cookie.checked = true;
    });
    applyCurrentCookies(preferences);
}

function setMinimalCookies(preferences) {
    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookie) => {
        cookie.checked = false;
    });
    applyCurrentCookies(preferences);
}

function bindPreferencesEvents(preferences) {
    document.querySelector('.js-accept-all-cookies', preferences).addEventListener('click', () => {
        acceptAllCookies(preferences);
        showButton(preferences, BUTTON_TYPE_CHANGED);
        displayCookieNotification(preferences);
    });

    document.querySelector('.js-accept-minimal-cookies', preferences).addEventListener('click', () => {
        setMinimalCookies(preferences);
        showButton(preferences, BUTTON_TYPE_ACCEPT_ALL);
        displayCookieNotification(preferences);
        history.back();
    });

    document.querySelector('.js-accept-custom-cookies', preferences).addEventListener('click', (event) => {
        applyCurrentCookies(preferences);
        toggleAcceptButton(preferences, event);
        displayCookieNotification(preferences);
    });

    [...document.querySelectorAll('.js-cookie-checkbox', preferences)].forEach((cookieOption) => {
        cookieOption.onchange = () => {
            toggleAcceptButton(preferences);
        };
    });
}

function highlightSelectedCookies(preferences) {
    const cookieCheckboxes = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];

    cookieCheckboxes.forEach((cookie) => {
        const cookieId = cookie.dataset.cookieId;
        const cookieRead = Cookies.get(`${ADDITIONAL_COOKIE_PREFIX + cookieId}`);

        cookie.checked = cookieRead === 'true';
    });
}

function displayCookieNotification(preferences) {
    const notificationMessage = preferences.getAttribute('data-cookie-notification-message');

    redirectIfNeeded();
    if (notificationMessage) {
        [...document.querySelectorAll('.js-cookie-notification-message')].forEach((element) => element.remove());

        const notificationMessageContainer = document.createElement('section');
        notificationMessageContainer.classList.add('cookie-notice-preferences-notification-message');
        notificationMessageContainer.classList.add('cookie-notice--darkgrey');
        notificationMessageContainer.classList.add('js-cookie-notification-message');
        notificationMessageContainer.innerHTML = notificationMessage;
        document.body.insertAdjacentElement('beforeend', notificationMessageContainer);

        setTimeout(() => {
            notificationMessageContainer.classList.add('hidden');
        }, 5000);
    }
}

function redirectIfNeeded() {
    const pageToRedirect = localStorage.getItem(PAGE_TO_RETURN_NAME);

    if (pageToRedirect) {
        localStorage.removeItem(PAGE_TO_RETURN_NAME);
        window.location = pageToRedirect;
    }
}


function handlePageLoadButtons(preferences) {
    const cookieCheckboxes = [...document.querySelectorAll('.js-cookie-checkbox', preferences)];
    const allEnabled = cookieCheckboxes.every((cookie) => cookie.checked === true);

    if (allEnabled) {
        showButton(preferences, BUTTON_TYPE_CHANGED);
    } else {
        showButton(preferences, BUTTON_TYPE_ACCEPT_ALL);
    }
}

function initCookiePreferences() {
    const preferences = document.querySelector('.js-cookie-preferences');

    highlightSelectedCookies(preferences);
    bindPreferencesEvents(preferences);
    handlePageLoadButtons(preferences);

    initDropdowns(preferences);
}

export {initCookiePreferences};
