import Cookies from 'js-cookie';
import Handlebars from 'handlebars';
import tippy from 'tippy.js';
import { getUserCoordinates, getCityName, getDealerCount } from './product-series-dealer-count';
import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';

import { COOKIE_ACCEPTED_NAME } from '../cookie/cookie-commons';

(function () {

    'use strict';

    window.jsB2c = window.jsB2c || {};

    const initProductSeriesHeaderStickyNav = () => {

        let productSeriesHeader = document.querySelector('.cmp-product-series-header');

        if (!document.querySelector('.product-series-header.js-product-series-header-sticky-navigation')) {
            return;
        }

        let productSeriesHeaderStickyNav = document.querySelector('.cmp-product-series-header__sticky-nav');
        let productSeriesHeaderStickyNavWrapper = document.querySelector('.cmp-product-series-header__sticky-nav--wrapper');

        if (!productSeriesHeader || !productSeriesHeaderStickyNav) {
            return;
        }

        const isStyleWarm = document.querySelector('.product-series-header')?.classList.contains('product-series-header--warm');
        if (isStyleWarm) {
            productSeriesHeaderStickyNav.classList.add('cmp-product-series-header__sticky-nav--style-warm');
        }

        const isStyleCold = document.querySelector('.product-series-header')?.classList.contains('product-series-header--cold');
        if (isStyleCold || (!isStyleWarm && !isStyleCold)) {
            productSeriesHeaderStickyNav.classList.add('cmp-product-series-header__sticky-nav--style-cold');
        }

        const productSeriesHeaderPrimaryButton = productSeriesHeader.querySelector('.cmp-product-series-header .cmp-product-series-header__cta-zone .primary-button .cmp-button');
        const productSeriesHeaderStickyNavPrimaryButtonWrapper = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__button--primary');
        const productSeriesHeaderStickyNavPrimaryButton = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__button--primary .cmp-button');
        const primaryCtaIsButton = productSeriesHeaderPrimaryButton?.tagName === 'BUTTON';
        if (productSeriesHeaderPrimaryButton) {
            productSeriesHeaderStickyNavPrimaryButtonWrapper.classList.add('cmp-product-series-header__sticky-nav__button--primary--active');
            productSeriesHeaderStickyNavPrimaryButton.querySelector('.cta-button__text').textContent = productSeriesHeaderPrimaryButton.querySelector('.cta-button__text').textContent;
            if (primaryCtaIsButton) {
                productSeriesHeaderStickyNavPrimaryButton.dataset.modalPath = productSeriesHeaderPrimaryButton.dataset.modalPath;
                productSeriesHeaderStickyNavPrimaryButton.classList.add('js-button-modal');
            } else {
                productSeriesHeaderStickyNavPrimaryButton.href = productSeriesHeaderPrimaryButton.href;
            }
        }

        const productSeriesHeaderSecondaryButton = productSeriesHeader.querySelector('.cmp-product-series-header .cmp-product-series-header__cta-zone .secondary-button .cmp-button');
        const productSeriesHeaderStickyNavSecondaryButton = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__button--secondary .cmp-button');
        const productSeriesHeaderStickyNavSecondaryButtonWrapper = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__button--secondary');
        const secondaryCtaIsButton = productSeriesHeaderPrimaryButton?.tagName === 'BUTTON';
        if (productSeriesHeaderSecondaryButton) {
            productSeriesHeaderStickyNavSecondaryButtonWrapper.classList.add('cmp-product-series-header__sticky-nav__button--secondary--active');
            productSeriesHeaderStickyNavSecondaryButton.querySelector('.cta-button__text').textContent = productSeriesHeaderSecondaryButton.querySelector('.cta-button__text').textContent;
            if (secondaryCtaIsButton) {
                productSeriesHeaderStickyNavSecondaryButton.dataset.modalPath = productSeriesHeaderPrimaryButton.dataset.modalPath;
                productSeriesHeaderStickyNavSecondaryButton.classList.add('js-button-modal');
            } else {
                productSeriesHeaderStickyNavSecondaryButton.href = productSeriesHeaderSecondaryButton.href;
            }
        }

        const productSeriesHeaderTitle = productSeriesHeader.querySelector('.cmp-product-series-header .cmp-product-series-header__name');
        const productSeriesHeaderStickyNavTitleWrapper = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__title');
        const productSeriesHeaderStickyNavTitle = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__title .cmp-title__text');
        if (productSeriesHeaderTitle) {
            productSeriesHeaderStickyNavTitleWrapper.classList.add('cmp-product-series-header__sticky-nav__title--active');
            productSeriesHeaderStickyNavTitle.textContent = productSeriesHeaderTitle.textContent;
        }

        const productSeriesHeaderToParentLink = productSeriesHeader.querySelector('.cmp-product-series-header .cmp-product-series-header__backlink');
        const productSeriesHeaderStickyNavToParentLinkWrapper = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__to-parent-link');
        const productSeriesHeaderStickyNavToParentLink = productSeriesHeaderStickyNav.querySelector('.cmp-product-series-header__sticky-nav__to-parent-link .cta-button');
        if (productSeriesHeaderToParentLink) {
            productSeriesHeaderStickyNavToParentLinkWrapper.classList.add('cmp-product-series-header__sticky-nav__to-parent-link--active');
            productSeriesHeaderStickyNavToParentLink.href = productSeriesHeaderToParentLink.href;
        }

        initializeDatalayerByContext(
            productSeriesHeader,
            false,
            true,
            undefined
        );

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: [0]
        };
    
        let handleStickyNav = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    productSeriesHeaderStickyNavWrapper.classList.remove('cmp-product-series-header__sticky-nav--wrapper--show');
                } else {
                    productSeriesHeaderStickyNavWrapper.classList.add('cmp-product-series-header__sticky-nav--wrapper--show');
                }
            });
        };
    
        let observer = new IntersectionObserver(handleStickyNav, options);
    
        observer.observe(productSeriesHeader);

        jsB2c.buttons.init()
    
    }

    async function initializeProductSeriesHeaders() {
        const productSeriesHeader = document.querySelector('.js-product-series-header');

        if (productSeriesHeader) {
            // Product Benefits
            const showMoreBenefitsTrigger = productSeriesHeader.querySelector('.js-product-series-benefits-trigger');
            if (showMoreBenefitsTrigger) {
                const benefits = showMoreBenefitsTrigger.closest('.js-product-series-header-benefits');
                const benefitToFocus = benefits.querySelector('.js-product-series-header-benefit:nth-child(3)');

                showMoreBenefitsTrigger.addEventListener('click', () => {
                    benefits.classList.add('js-product-series-header-benefits-show-all');
                    benefitToFocus.setAttribute('tabindex', '-1');
                    benefitToFocus.focus();
                    showMoreBenefitsTrigger.parentNode.removeChild(showMoreBenefitsTrigger);
                }, false);
            }

            // Dealer Count
            const dealerCountContainer = productSeriesHeader.querySelector('.js-product-series-dealer-count');
            if (dealerCountContainer && Cookies.get(COOKIE_ACCEPTED_NAME)) {
                try {
                    const labelSingle = dealerCountContainer.dataset.productSeriesDealerCountLabelSingle;
                    const labelPlural = dealerCountContainer.dataset.productSeriesDealerCountLabelPlural;
                    const radius = dealerCountContainer.dataset.productSeriesDealerLocatorRadius;
                    const dealerLocatorSearchEndpoint = dealerCountContainer.dataset.dealerLocatorSearchEndpoint;
                    const googleApiKey = dealerCountContainer.dataset.googleMapsKey;

                    const position = await getUserCoordinates();
                    const cityName = await getCityName(position, googleApiKey);
                    const amount = await getDealerCount(position, radius, dealerLocatorSearchEndpoint);

                    if (amount && cityName && labelSingle && labelPlural) {
                        const templateData = {
                            'amount': amount,
                            'location': cityName
                        };
                        const templateHandlebars = Handlebars.compile(amount > 1 ? labelPlural : labelSingle);
                        dealerCountContainer.textContent = templateHandlebars(templateData);
                        dealerCountContainer.classList.remove('hidden');
                    }
                } catch (error) {
                    console.error(error);
                }
            }

            // Tooltips
            const hideOnEsc = {
                name: 'hideOnEsc',
                defaultValue: true,
                fn({hide}) {
                    function onKeyDown(event) {
                        if (event.keyCode === 27) {
                            hide();
                        }
                    }

                    return {
                        onShow() {
                            document.addEventListener('keydown', onKeyDown);
                        },
                        onHide() {
                            document.removeEventListener('keydown', onKeyDown);
                        },
                    };
                },
            };

            // Product Series header Tooltip based on selected theme
            let productSeriesTippyTheme;
            if (productSeriesHeader.classList.contains('product-series-header--warm')) {
                productSeriesTippyTheme = 'b2c-warm';
            } else {
                productSeriesTippyTheme = 'b2c-cold';
            }

            const productSeriesHeaderTooltips = productSeriesHeader.querySelectorAll('[data-tippy]');

            tippy(productSeriesHeaderTooltips, {
                plugins: [hideOnEsc],
                interactive: true,
                placement: 'top',
                maxWidth: 260,
                theme: productSeriesTippyTheme
            });
        }
        
    }

    if (document.readyState !== 'loading') {
        initializeProductSeriesHeaders();
        initProductSeriesHeaderStickyNav();
    } else {
        document.addEventListener('DOMContentLoaded', () => {
            initializeProductSeriesHeaders();
            initProductSeriesHeaderStickyNav();
        });
    }

    jsB2c.productSeriesHeaders = (function () {
        return {
            init: initializeProductSeriesHeaders,
        };
    })();

})();
