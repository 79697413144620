(function () {

    'use strict';

    if (!document.querySelector('#daikin-general-search')) {
        return;
    }

    window._state = {

        tab: false,

        contentCount: false,
        faqsCount: false,
        productCount: false
    }

    window._handler = {

        get: function (obj, prop) {

            console.log('Getting', prop);

            if (typeof obj[prop] === 'object' && obj[prop] !== null) {

                console.log('getting nested');
                return new Proxy(obj[prop], _handler);

            } else {

                console.log('getting base');
                return obj[prop];

            }

        },

        set: function (obj, prop, value) {

            console.log('Setting', prop, 'to', value);

            obj[prop] = value;

            if ( prop === 'tab' ) {
                // switchResultsTab();
            }

            if ( prop === 'faqsCount') {
                updateFaqsTitleCount();
            }

            if ( prop === 'contentCount' || prop === 'faqsCount' ) {
                updateRelatedContentCount();
            }

            if ( prop === 'productCount' ) {
                updateResultsCount('product', value);
            }

            return true;
        }

    }

    window.searchStateProxy = new Proxy(_state, _handler);

    // Legacy variable suuport
    // window.searchStateProxy = _search;

    /**
     * REFACTOR BELOW
     */

    function updateRelatedContentCount() {
        updateResultsCount('content', searchStateProxy.contentCount + searchStateProxy.faqsCount);
    }

    function updateFaqsTitleCount() {
        if (searchStateProxy.faqsCount && document.querySelector('#general-search-cmp-foldout__title__count')) {
            document.querySelector('#general-search-cmp-foldout__title__count').innerText = searchStateProxy.faqsCount;
        }
    }

    function isMobile() {
        return window.matchMedia("(max-width: 576px)").matches;
    }
    
    const boldQuery = (str, query) => {
        const n = str.toUpperCase();
        const q = query.toUpperCase();
        const x = n.indexOf(q);
        if (!q || x === -1) {
            return str;
        }
        const l = q.length;
        return str.substr(0, x) + '<b>' + str.substr(x, l) + '</b>' + str.substr(x + l);
    }

    function autocomplete(inp) {
        
        var currentFocus;
        var timer;
        inp.addEventListener("input", function(e) {
            var a, b, i, val = this.value;          
            currentFocus = -1;
            closeAllLists();
            if (!val || (val && val.length < 2)) {
                return false;
            }

            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            this.parentNode.appendChild(a);
            
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(async function(){
                let autocompleteResult = await solrAutocomplete();

                for (i = 0; i < autocompleteResult.length; i++) {
                    if (autocompleteResult[i].toUpperCase().includes(val.toUpperCase())) {
                    b = document.createElement("DIV");
                    b.innerHTML = boldQuery(autocompleteResult[i], val);        
                    b.innerHTML += "<input type='hidden' value='" + autocompleteResult[i] + "'>";
                    b.addEventListener("click", function(e) {
                        inp.value = this.getElementsByTagName("input")[0].value;                
                        closeAllLists();
                        newSearch();
                    });
                    a.appendChild(b);
                    }
                }
            }, 100);            
            
        });

        inp.addEventListener("keydown", function(e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                currentFocus++;
                addActive(x);
            } else if (e.keyCode == 38) {
                currentFocus--;
                addActive(x);
            } else if (e.keyCode == 13) {
                if (currentFocus > -1) {
                    if (x) x[currentFocus].click();
                } else {
                    closeAllLists();
                }
            }
        });
      
        function addActive(x) {
            if (!x) return false;
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            x[currentFocus].classList.add("autocomplete-active");
        }
      
        function removeActive(x) {
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
      
        function closeAllLists(elmnt) {
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        document.addEventListener("click", function(e) {
            closeAllLists(e.target);
        });
    }

    function getQueryParam() {
        const searchInput = document.querySelector('.cmp-general-search__box input[type=search]');
        if (searchInput && searchInput.value !== '') {
            return searchInput.value;
        } else {
            return null;
        }
    }

    function getFilters(type) {
        let response = '';
        let checkBoxesChecked = null;
        if (type === 'content') {
            checkBoxesChecked = document.querySelectorAll('#content-filter-rail-list input[name=content-checkbox]:checked');

            let subTypesChecked = document.querySelectorAll('#content-filter-rail-list input[name=subtype-checkbox]:checked');
            if (subTypesChecked.length > 0) {
                [...subTypesChecked].forEach((item) => {
                    response = response + '&' + item.id.replace('__', '=');
                });
            }

            let sortByChecked = document.querySelector('#content-filter-rail-list input[type=radio]:checked');
            if (sortByChecked) {
                response = response + '&' + sortByChecked.id.replace('__', '=');
            }
        } else {
            checkBoxesChecked = document.querySelectorAll('#product-filter-rail-list input[name=product-checkbox]:checked');
        }

        if (checkBoxesChecked.length > 0) {
            [...checkBoxesChecked].forEach((item) => {
                response = response + '&f.facet_' + item.id.replace('____', '=').replace('__', '=');
            });
        }

        return response;
    }

    function updateFilterCount() {
        const type = getCurrentType();
        const openFilter = document.querySelector('#' + type + '-filter-rail-list .facet-filter__buttonOpen .facet-filter__text');
        const originalText = openFilter.getAttribute('data-cmp-facet');
        const filterCount = document.querySelectorAll('#' + type + '-filter-rail-list .facet-filter:has(.facet-filter__buttonOpen)+.checkbox-list-wrapper .checkbox-list input:checked').length;
        if (filterCount > 0) {
            openFilter.innerText = originalText + ' (' + filterCount + ')';
        } else {
            openFilter.innerText = originalText;
        }
    }

    function clearFilters() {
        const visibleFilters = document.querySelectorAll('#' + getCurrentType() + '-filter-rail-list .facet-filter:has(.facet-filter__buttonOpen)+.checkbox-list-wrapper .checkbox-list input');
        if (visibleFilters.length) {
            [...visibleFilters].forEach(i => i.checked = false);
        }
        updateFilterCount();
        solrCall();
    }

    function clearFiltersMobile() {
        const currentType = getCurrentType();
        const visibleFilters = document.querySelectorAll('#' + currentType + '-filter-rail-list input:checked');
        if (visibleFilters.length) {
            [...visibleFilters].forEach(i => i.checked = false);
        }
        const allFacets = document.querySelectorAll('#' + currentType + '-filter-rail-list .facet-filter > button > span');
        if (allFacets.length) {
            [...allFacets].forEach(f => f.innerText = f.getAttribute('data-cmp-facet'));
        }
    }

    function toggleFiltersMobile() {
        const currentRail = getCurrentType() === 'content' ? document.getElementById('content-filter-rail-list') : document.getElementById('product-filter-rail-list');
        currentRail.classList.toggle('filter-rail__mobile');
        if (currentRail.classList.contains('filter-rail__mobile') !== true) {
            var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
            while (filtersOpened.length) {
                filtersOpened[0].classList.remove("facet-filter__buttonOpen");
            }
        }
    }

    function toggleFilters() {
        document.getElementById('content-filter-rail-list').classList.toggle('hidden');
        document.getElementById('product-filter-rail-list').classList.toggle('hidden');
    }

    function toggleSearchResults() {
        document.getElementById('daikin-general-search-results-product').classList.toggle('hidden');
        document.getElementById('daikin-general-search-results-content').classList.toggle('hidden');
        document.getElementById('daikin-general-search-results-faq').classList.toggle('hidden');
    }

    function getCurrentType() {
        return document.getElementsByClassName('btn-product').length === 0 ? 'content' : 'product';
    }

    function getCurrentPageId() {
        return getCurrentType() === 'product' ? 'productCurrentPage' : 'contentCurrentPage';
    }

    function getLastPageId() {
        return getCurrentType() === 'product' ? 'productLastPage' : 'contentLastPage';
    }

    function getCurrentPage() {
        return Number.parseInt(sessionStorage.getItem(getCurrentPageId()));
    }

    function getLastPage() {
        return Number.parseInt(sessionStorage.getItem(getLastPageId()));
    }

    function navigateTo(path) {
        if (path.indexOf('html') === -1) path += '.html';
        window.location.assign(path);
    }

    function truncate(str, n) {
        if (str.length <= n) { return str; }
        const subString = str.substr(0, n - 1);
        return subString.substr(0, subString.lastIndexOf(" ")) + "...";
    }

    function removeMobileClass() {
        if (((document.getElementById('product-filter-rail-list').classList.contains('filter-rail__mobile')) && isMobile() !== true) || ((document.getElementById('content-filter-rail-list').classList.contains('filter-rail__mobile')) && isMobile() !== true)) {
            var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
            while (filtersOpened.length) {
                filtersOpened[0].classList.remove("facet-filter__buttonOpen");
            }
        }
        if (isMobile() !== true) {
            document.getElementById('content-filter-rail-list').classList.remove('filter-rail__mobile');
            document.getElementById('product-filter-rail-list').classList.remove('filter-rail__mobile');
        }
    }

    function removeDesktopClass() {
        document.querySelectorAll('.facet-filter button[disabled]')?.forEach(btn => btn.disabled = false);
        var filtersOpened = document.getElementsByClassName("facet-filter__buttonOpen");
        while (filtersOpened.length) {
            filtersOpened[0].classList.remove("facet-filter__buttonOpen");
        }
        let clearFilters = document.getElementsByClassName("filter-rail-clear");
        [...clearFilters].forEach((elem) => { elem.style.display = 'none' });
    }

    function handleResize() {
        if (isMobile()) {
            removeDesktopClass();
        } else {
            removeMobileClass();
        }
    }

    function newSearch() {
        sessionStorage.setItem('contentCurrentPage', 1);
        sessionStorage.setItem('productCurrentPage', 1);
        sessionStorage.removeItem('contentLastPage');
        sessionStorage.removeItem('productLastPage');
        document.getElementById('search-button-first').classList.add('active');
        document.getElementById('search-button-mid').classList.remove('active');
        document.getElementById('search-button-last').classList.remove('active');
        document.getElementById('search-button-mid').innerText = '...';
        if (document.querySelector('.btn-product') || document.querySelector('.btn-service')) {
            solrCall('product');
            solrContentCall();
        } else {
            solrContentCall();
        }
    }

    function switchResultsTab() {
        const lastPage = getLastPage();
        const currentType = getCurrentType();
        let pagingDiv = document.querySelector('div.search-paging');
        let noSearchResults = document.getElementById('daikin-general-search-no-results');
        let currentAmount = document.getElementById('daikin-general-search-results-' + currentType).getElementsByClassName('result-list__item').length;
        let currentResults = document.getElementById('daikin-general-search-results-' + currentType);
        if (currentAmount === 0) {
            noSearchResults.style.display = 'block';
            currentResults.style.display = 'none';
        } else {
            noSearchResults.style.display = 'none';
            currentResults.style.display = 'grid';
        }
        if (lastPage <= 1) {
            pagingDiv.style.display = 'none';
        } else {
            pagingDiv.style.display = 'flex';
            const currentPage = getCurrentPage();
            const isFirstPage = (currentPage === 1);
            const isLastPage = (currentPage === lastPage);
            let firstPageButton = document.getElementById('search-button-first');
            let midPageButton = document.getElementById('search-button-mid');
            let lastPageButton = document.getElementById('search-button-last');
            if (isFirstPage === true) {
                midPageButton.classList.remove('active');
                lastPageButton.classList.remove('active');
                firstPageButton.classList.add('active');
            } else if (isLastPage === true) {
                midPageButton.classList.remove('active');
                lastPageButton.classList.add('active');
                firstPageButton.classList.remove('active');
            }
            if (isFirstPage === false && isLastPage === false) {
                midPageButton.innerText = currentPage;
                midPageButton.classList.add('active');
                lastPageButton.classList.remove('active');
                firstPageButton.classList.remove('active');
            }
            if (lastPage === 2) {
                midPageButton.style.display = 'none';
            } else {
                midPageButton.style.display = 'block';
            }
            document.getElementById('search-button-prev').disabled = isFirstPage;
            firstPageButton.disabled = isFirstPage;
            lastPageButton.disabled = isLastPage;
            document.getElementById('search-button-next').disabled = isLastPage;
        }
    }

    function changePage(id) {
        let currentPage = getCurrentPage();
        switch (id) {
            case 'search-button-prev':
                currentPage -= 1;
                break;
            case 'search-button-next':
                currentPage += 1;
                break;
            case 'search-button-first':
                currentPage = 1;
                break;
            case 'search-button-last':
                currentPage = getLastPage();
                break;
            default:
                break;
        }
        sessionStorage.setItem(getCurrentPageId(), currentPage);
        switchResultsTab();
        solrCall();
        document.querySelector('#daikin-general-search-header').scrollIntoViewIfNeeded();
    }

    function initPaging() {
        const pagingButtons = document.querySelectorAll("button[id^='search-button']");
        pagingButtons.forEach((btn) => {
            btn.addEventListener('click', (e) => changePage(e.target.id));
        });
    }

    function createArrowSvg() {
        const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        const iconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        iconSvg.setAttribute('width', '32');
        iconSvg.setAttribute('height', '33');
        iconSvg.setAttribute('viewBox', '0 0 32 33');
        iconSvg.setAttribute('fill', 'none');
        iconPath.setAttribute('d', 'M21.9603 9.55762L20.547 10.971L25.507 15.931H2.66699V17.931H25.507L20.547 22.891L21.9603 24.3043L29.3337 16.931L21.9603 9.55762Z');
        iconSvg.appendChild(iconPath);
        return iconSvg;
    }

    function getImageSrc(cardImg) {
        let imgSrc = '/content/dam/DACI-Internet/placeholder/placeholder_34.png';
        if (typeof cardImg !== 'undefined') {
            imgSrc = cardImg;
        }
        return imgSrc + '/_jcr_content/renditions/cq5dam.thumbnail.319.319.png';
    }

    function createProductCardBody(parent, card) {
        const cardImg = parent.querySelector('img');
        cardImg.setAttribute('src', getImageSrc(card.variant_1_product_shot));
        let cardTitle = document.createElement('h3');
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.product_name;
        cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        let cardDescription = document.createElement('p');
        cardDescription.classList.add('result-list__description');
        cardDescription.innerText = truncate(card.product_description, 120);
        cardDescription.addEventListener('click', function () { navigateTo(card.path) });

        let cardFooter = document.createElement('div');
        cardFooter.classList.add('result-list__color');
        let totalColors = Object.keys(card).filter(value => /variant_[1-5]_id/.test(value)).length;
        if (totalColors > 1) {
            let mainColor = document.createElement('img');
            mainColor.classList.add('result-list__color__circle');
            mainColor.src = card.variant_1_swatch_image;
            let otherColors = document.createElement('p');
            otherColors.classList.add('result-list__color__text');
            let generalSearch = document.getElementById('daikin-general-search');
            let otherColorsLabel = generalSearch.getAttribute('data-cmp-label-colors');
            if (totalColors === 2) {
                otherColorsLabel = generalSearch.getAttribute('data-cmp-label-color');
            }
            otherColors.innerText = otherColorsLabel.replace('{0}', totalColors - 1);
            cardFooter.appendChild(mainColor);
            cardFooter.appendChild(otherColors);
        }

        parent.appendChild(cardTitle);
        parent.appendChild(cardDescription);
        parent.appendChild(cardFooter);

        return parent;
    }

    function createContentCardBody(parent, card) {

        const cardImg = parent.querySelector('img');
        const cardHeading = document.createElement('p');
        const cardTitle = document.createElement('h3');
        const cardFooter = document.createElement('div');

        cardImg.setAttribute('src', getImageSrc(card.featured_image));
        const generalSearchHeader = document.getElementById('daikin-general-search-header');
        if (generalSearchHeader.getAttribute('data-search-subtype') === 'article') {
            cardHeading.classList.add('result-list__heading-article');
            cardHeading.innerText = "";
        }
        else {
            cardHeading.innerText = card.subtype;
        }
        cardHeading.addEventListener('click', function () { navigateTo(card.path) });
        cardTitle.classList.add('result-title__text');
        cardTitle.innerText = card.title;
        cardTitle.addEventListener('click', function () { navigateTo(card.path) });
        cardFooter.appendChild(createArrowSvg());
        cardFooter.addEventListener('click', function () { navigateTo(card.path) });
        if (card.subtype === 'category') {
            parent.classList.add('result-list__category');
            cardHeading.classList.add('result-list__heading');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__cold');
        } else {
            cardHeading.classList.add('result-list__heading-content');
            cardFooter.classList.add('result-list__arrow', 'result-list__arrow__warm');
            let footerText = document.createElement('p');
            footerText.classList.add('result-list__arrow__text');
            footerText.innerText = document.getElementById('daikin-general-search').getAttribute('data-cmp-label-readmore');
            cardFooter.appendChild(footerText);
        }

        if (card.subtype === 'category') {
            let categoryCardDiv = document.createElement('div');
            categoryCardDiv.classList.add('result-list__info');
            categoryCardDiv.appendChild(cardHeading);
            categoryCardDiv.appendChild(cardTitle);
            categoryCardDiv.appendChild(cardFooter);
            parent.appendChild(categoryCardDiv);
        } else {
            parent.appendChild(cardHeading);
            parent.appendChild(cardTitle);
            parent.appendChild(cardFooter);
        }

        return parent;
    }

    function appendCard(card) {
        let listItem = document.createElement('li');
        listItem.classList.add('result-list__item');

        let cardImage = document.createElement('img');
        cardImage.classList.add('result-list__image', 'cmp-image', 'cmp-image__image');
        cardImage.addEventListener('click', function () { navigateTo(card.path) });
        listItem.appendChild(cardImage);

        let searchResultsId = 'daikin-general-search-results-product';
        if (card.type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
            listItem = createContentCardBody(listItem, card);
        } else if (card.type === 'product' && card.subtype === 'category') {
            listItem = createContentCardBody(listItem, card);
        } else {
            listItem = createProductCardBody(listItem, card);
        }

        document.getElementById(searchResultsId).appendChild(listItem);
    }

    function appendFaq(faq, i) {    
        const accordionHeader = document.createElement('h3');
        accordionHeader.classList.add('cmp-accordion__header');
        const accordionButton = document.createElement('button');
        accordionButton.classList.add('cmp-accordion__button');
        const accordionTitle = document.createElement('span');
        accordionTitle.classList.add('cmp-accordion__title');
        accordionTitle.innerText = faq.title;
        const accordionIcon = document.createElement('span');
        accordionIcon.classList.add('cmp-accordion__icon');

        accordionButton.appendChild(accordionTitle);
        accordionButton.appendChild(accordionIcon);
        accordionHeader.appendChild(accordionButton);

        const accordionPanel = document.createElement('div');
        accordionPanel.classList.add('cmp-accordion__panel', 'hidden');
        const panelText = document.createElement('div');
        panelText.classList.add('cmp-text');
        panelText.innerText = faq.description.replace(/<\/?[^>]+(>|$)/g, '');

        accordionPanel.appendChild(panelText);

        const accordionItem = document.createElement('div');
        accordionItem.classList.add('cmp-accordion__item');
        accordionItem.addEventListener('click', (item) => {
            item.target.querySelector('.cmp-accordion__panel').classList.toggle('hidden');
            item.target.querySelector('.cmp-accordion__icon').classList.toggle('open');
        });

        accordionItem.appendChild(accordionHeader);
        accordionItem.appendChild(accordionPanel);
        
        document.getElementById('daikin-general-search-results-faq-accordion').appendChild(accordionItem);
    }

    // updateResultsCount('content', 1);
    function updateResultsCount(type, total) {
        let tabId = 'general-search-tab-1';
        if (type === 'product') {
            document.getElementById('search-results-num-product').innerText = total;
        } else if (type === 'content') {
            tabId = 'general-search-tab-2';

            

            document.getElementById('search-results-num-content').innerText = total;
        }
        let currentTab = document.getElementById(tabId);
        let label = currentTab.dataset.labelPlural;
        if (total === 1) {
            label = currentTab.dataset.labelSingular;
        }
        currentTab.innerText = label;
    }

    function solrContentCall() {
        solrCall('content', true);
        solrCall('content', false);
    }

    async function solrAutocomplete(type) {
        type = (typeof type !== 'undefined') ? type : getCurrentType();
        let searchResultsId = 'daikin-general-search-results-product';
        if (type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
        }
        const generalSearch = document.getElementById('daikin-general-search');
        if (generalSearch) {
            let subtypeParam = '';
            const generalSearchHeader = document.getElementById('daikin-general-search-header');
            if (generalSearchHeader && generalSearchHeader.getAttribute('data-search-subtype') !== null) {
                subtypeParam = '&subtype=' + generalSearchHeader.getAttribute('data-search-subtype');
            }
            const searchEndpoint = generalSearch.getAttribute('data-cmp-endpoint').replace('.b2cquery.','.b2cautocomplete.');
            const langParam = generalSearch.getAttribute('data-cmp-language');
            const queryParam = getQueryParam() !== null ? '&title=' + getQueryParam() : '';
            const rows = sessionStorage.getItem('searchRows') !== null ? sessionStorage.getItem('searchRows') : 12;
            const offsetParam = '&offset=' + Math.max(((getCurrentPage() - 1) * rows), 0);
            try {
                const requestUrl = searchEndpoint + '?language=' + langParam + offsetParam + queryParam + '&type=' + type + subtypeParam + getFilters(type);
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                let autocompleteResult = [];
                if (responseData.results) {                                  
                    [...responseData.results].forEach((card) => {
                        autocompleteResult.push(card.title);
                    });
                }

                return autocompleteResult;

            } catch (error) {
                console.error(error);
            }

        }
    }

    async function solrCall(type, includeFaqs) {

        type = (typeof type !== 'undefined') ? type : getCurrentType();
        includeFaqs = (typeof includeFaqs !== 'undefined') ? includeFaqs : false;
        let searchResultsId = 'daikin-general-search-results-product';
        let faqParam = '';
        if (type === 'content') {
            searchResultsId = 'daikin-general-search-results-content';
            faqParam = '&faq=' + includeFaqs.toString();
        }
        const generalSearch = document.getElementById('daikin-general-search');
        if (generalSearch) {
            
            let subtypeParam = '';
            const generalSearchHeader = document.getElementById('daikin-general-search-header');
            if (generalSearchHeader && generalSearchHeader.getAttribute('data-search-subtype') !== null) {
                subtypeParam = '&subtype=' + generalSearchHeader.getAttribute('data-search-subtype');
            }
            const searchEndpoint = generalSearch.getAttribute('data-cmp-endpoint');
            const langParam = generalSearch.getAttribute('data-cmp-language');
            const queryParam = getQueryParam() !== null ? '&q=' + getQueryParam() : '';
            const rows = sessionStorage.getItem('searchRows') !== null ? sessionStorage.getItem('searchRows') : 12;
            const offsetParam = '&offset=' + Math.max(((getCurrentPage() - 1) * rows), 0);
            try {
                const requestUrl = searchEndpoint + '?language=' + langParam + offsetParam + queryParam + '&type=' + type + subtypeParam + faqParam + getFilters(type);
                const result = await fetch(requestUrl);
                const responseData = await result.json();

                if (responseData.results) {
                    document.getElementById(searchResultsId).textContent = '';
                    if (type === 'product' || includeFaqs === false) {
                        processSolrResponse(responseData, type);
                    } else {

                        /* Getting only the rows as the FAQs don't have pagination */
                        searchStateProxy.faqsCount = responseData.total < responseData.rows ? responseData.total : responseData.rows;

                        // searchStateProxy.faqsCount = responseData.total;
                        [...responseData.results].forEach((faq, i) => {
                            appendFaq(faq, i);
                        });
                    }
                }

            } catch (error) {
                console.error(error);
            }

        }
    }

    function processSolrResponse(responseData, type) {

        let lastPageId = type + 'LastPage';
        let noSearchResults = document.getElementById('daikin-general-search-no-results');
        let noResultsText = sessionStorage.getItem('noResultsText');
        if (noResultsText !== null) {
            document.querySelector('#daikin-general-search-no-results > p').innerText = noResultsText.replace('{searchQuery}', getQueryParam());
        }
        let pagingDiv = document.querySelector('div.search-paging');
        let lastPage = Math.ceil(responseData.total / responseData.rows);
        sessionStorage.setItem('searchRows', responseData.rows);
        let lastPageButton = document.getElementById('search-button-last');
        sessionStorage.setItem(lastPageId, lastPage);
        if (type === getCurrentType()) {
            if (lastPage === 1) {
                pagingDiv.style.display = 'none';
            } else {
                if (lastPage === 2) {
                    document.getElementById('search-button-mid').style.display = 'none';
                } else {
                    document.getElementById('search-button-mid').style.display = 'block';
                }
                lastPageButton.innerText = lastPage;
                pagingDiv.style.display = 'flex';
            }
            let currentResults = document.getElementById('daikin-general-search-results-' + type);
            if (responseData.results.length === 0) {
                noSearchResults.style.display = 'block';
                pagingDiv.style.display = 'none';
                currentResults.style.display = 'none';
            } else {
                noSearchResults.style.display = 'none';
                currentResults.style.display = 'grid';
            }
        }
        if (document.querySelector('.btn-product') || document.querySelector('.btn-service')) {
            if (type === 'product') {
                searchStateProxy.productCount = responseData.total;
            } else {
                searchStateProxy.contentCount = responseData.total;
            }
        }
        [...responseData.results].forEach((card) => {
            appendCard(card);
        });
    }

    function checkFilters(filters, item, type) {
        const clearFiltersClass = 'filter-rail-clear__' + type;
        const clearFilters = document.getElementsByClassName(clearFiltersClass);
        if (item.classList.contains('facet-filter__buttonOpen')) {
            [...filters].forEach((elem) => {
                if (isMobile() !== true) {
                    elem.classList.remove("facet-filter__buttonOpen");
                    elem.disabled = false;
                } else {
                    item.classList.remove("facet-filter__buttonOpen");
                }
            });
            [...clearFilters].forEach((elem) => { elem.style.display = 'none' });
        } else {
            [...filters].forEach((elem) => {
                if (item !== elem) {
                    if (isMobile() !== true) {
                        elem.disabled = true;
                    }
                }
            });
            item.classList.add('facet-filter__buttonOpen');
            let display = 'block';
            if (item.classList.contains('facet-sortby') || isMobile() === true) {
                display = 'none';
            }
            [...clearFilters].forEach((elem) => { elem.style.display = display });
        }
    }

    const generalSearchInit = () => {
        document.documentElement.style.setProperty('--filters-column', document.querySelector('#daikin-general-search').dataset.facetsPerColumn);
        const productFilters = document.querySelectorAll('#product-filter-rail-list .facet-filter__button');
        const contentFilters = document.querySelectorAll('#content-filter-rail-list .facet-filter__button');
        const productCheckboxes = document.querySelectorAll('#product-filter-rail-list input[type=checkbox]');
        const contentCheckboxes = document.querySelectorAll('#content-filter-rail-list input[type=checkbox]');
        const searchInput = document.querySelector('.cmp-general-search__box input[type=search]');
        const noResults = document.querySelector('#daikin-general-search-no-results > p');
        if (noResults) {
            sessionStorage.setItem('noResultsText', noResults.innerText);
        }
        sessionStorage.setItem('productCurrentPage', 1);
        sessionStorage.setItem('contentCurrentPage', 1);
        initPaging();

        if (productFilters.length) {
            [...productFilters].forEach((item) => {
                item.addEventListener('click', () => { checkFilters(productFilters, item, 'product') });
            });
        }

        if (contentFilters.length) {
            [...contentFilters].forEach((item) => {
                item.addEventListener('click', () => { checkFilters(contentFilters, item, 'content') });
            });
        }

        if (searchInput) {
            searchInput.addEventListener('search', newSearch);
        }

        if (productCheckboxes.length) {
            [...productCheckboxes].forEach((item) => {
                item.addEventListener('change', () => {
                    sessionStorage.setItem('productCurrentPage', 1);
                    updateFilterCount();
                    if (isMobile() !== true) solrCall('product');
                });
            });
        }

        if (contentCheckboxes.length) {
            [...contentCheckboxes].forEach((item) => {
                item.addEventListener('change', () => {
                    sessionStorage.setItem('contentCurrentPage', 1);
                    updateFilterCount();
                    if (isMobile() !== true) solrContentCall();
                });
            });
        }

        const clearFiltersButtons = document.getElementsByClassName('filter-rail-clear');
        if (clearFiltersButtons.length) {
            [...clearFiltersButtons].forEach((item) => {
                item.addEventListener('click', clearFilters);
            });
        }

        window.addEventListener("resize", handleResize);

        const urlParams = new URLSearchParams(window.location.search);
        const urlQuery = urlParams.get('q');
        if (urlQuery) {
            searchInput.value = urlQuery;
        }

        const relevancySortBy = document.getElementById('sortby__relevancy');
        const recencySortBy = document.getElementById('sortby__recency');
        relevancySortBy.checked = searchInput.value !== '';
        recencySortBy.checked = searchInput.value === '';
        if (isMobile() !== true) {
            recencySortBy.checked = searchInput.value === '';
            relevancySortBy.addEventListener('change', () => { solrContentCall() });
            recencySortBy.checked = searchInput.value === '';
            recencySortBy.addEventListener('change', () => { solrContentCall() });
        }

        const searchButton = document.querySelector('.search-icon');
        searchButton.addEventListener('click', newSearch);

        const clearBtn = document.getElementById('general-search-clear-button');
        clearBtn.addEventListener('click', () => {
            searchInput.value = '';
            newSearch();
        });

        const productBtn = document.querySelector('.btn-product');
        const serviceBtn = document.querySelector('.btn-service');
        if (serviceBtn && productBtn) {
            serviceBtn.classList.remove('btn-service');

            productBtn.addEventListener('focus', () => {
                searchStateProxy.tab = 'products';
                searchStateProxy.test = 'products';
                searchStateProxy.test2 = {};
                searchStateProxy.test2.test3 = 'products 2222222222';
                productBtn.classList.add('btn-product');
                serviceBtn.classList.remove('btn-service');
                document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-cold-500)');
                document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-cold-200)');
                toggleSearchResults();
                toggleFilters();
                document.getElementById('search-button-last').innerText = getLastPage();
                document.getElementById('search-button-mid').innerText = '...'
                switchResultsTab();
            });

            serviceBtn.addEventListener('focus', () => {
                setupFaqsShowMoreListener();
                searchStateProxy.tab = 'content';
                productBtn.classList.remove('btn-product');
                serviceBtn.classList.add('btn-service');
                document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-warm-500)');
                document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-warm-200)');
                toggleSearchResults();
                toggleFilters();
                document.getElementById('search-button-last').innerText = getLastPage();
                document.getElementById('search-button-mid').innerText = '...'
                switchResultsTab();
            });
        } else {
            toggleFilters();
            toggleSearchResults();
            if (document.getElementById('content-type-button')) {
                document.getElementById('content-type-button').classList.toggle('hidden');
            }
            if (document.getElementById('content-type-checkbox-list')) {
                document.getElementById('content-type-checkbox-list').classList.toggle('hidden');
            }
        }
        const generalSearchHeader = document.getElementById('daikin-general-search-header');
        if (generalSearchHeader.getAttribute('data-search-subtype') === 'article') {
            document.querySelector(':root').style.setProperty('--color-accent', 'var(--color-warm-500)');
            document.querySelector(':root').style.setProperty('--color-accent-disabled', 'var(--color-warm-200)');
        }
        const openFiltersMob = document.querySelector('#daikin-general-search .open-filters');
        if (openFiltersMob) {
            openFiltersMob.addEventListener('click', toggleFiltersMobile);
        }

        const closeFiltersContentMob = document.querySelector('#daikin-general-search .close-filters-content');
        if (closeFiltersContentMob) {
            closeFiltersContentMob.addEventListener('click', toggleFiltersMobile);
        }

        const closeFiltersProductsMob = document.querySelector('#daikin-general-search .close-filters-products');
        if (closeFiltersProductsMob) {
            closeFiltersProductsMob.addEventListener('click', toggleFiltersMobile);
        }

        const clearFiltersMob = document.querySelectorAll('.footer-search-menu .secondary-button button');
        if (clearFiltersMob.length) {
            [...clearFiltersMob].forEach((item) => {
                item.addEventListener('click', clearFiltersMobile);
            });
        }

        const applyFiltersProduct = document.querySelector('#product-filter-rail-list .footer-search-menu .primary-button button');
        if (applyFiltersProduct) {
            applyFiltersProduct.addEventListener('click', () => {
                toggleFiltersMobile();
                solrCall('product');
            });
        }

        const applyFiltersContent = document.querySelector('#content-filter-rail-list .footer-search-menu .primary-button button');
        if (applyFiltersContent) {
            applyFiltersContent.addEventListener('click', () => {
                toggleFiltersMobile();
                solrContentCall();
            });
        }

        

        
        setupFaqsShowMoreListener();

        newSearch();
        autocomplete(document.getElementById('general-search-input'));
    };

    function setupFaqsShowMoreListener() {
        console.log('setupFaqsShowMoreListener');
        const faqAccordion = document.querySelector('#daikin-general-search-results-faq');
        faqAccordion.classList.add('search-faqs--short');
        const faqCollapseBtn = faqAccordion && faqAccordion.querySelector('.faq-showmore');
        if (faqCollapseBtn) {
            faqCollapseBtn.removeEventListener('click', handleFaqShowMoreListener);
            faqCollapseBtn.addEventListener('click', handleFaqShowMoreListener);
        }
    }

    function handleFaqShowMoreListener(event) {
        const faqAccordion = event.target.closest('#daikin-general-search-results-faq');
        const faqCollapseBtn = faqAccordion && faqAccordion.querySelector('.faq-showmore');
        faqAccordion.classList.toggle('search-faqs--short');
        faqCollapseBtn.classList.toggle('faq-showmore--less');
    }

    if (document.readyState !== 'loading') {
        generalSearchInit();
    } else {
        document.addEventListener('DOMContentLoaded', generalSearchInit);
    }

}());
