import { initializeDatalayerByContext } from '/src/site/utilities/datalayer-custom';

(function () {

    const init = () => {

        if (!document.querySelector('.standard-header.js-standard-header-sticky-navigation')) {
            return;
        }

        let standardHeader = document.querySelector('.cmp-standard-header');
        let standardHeaderStickyNav = document.querySelector('.cmp-standard-header__sticky-nav');
        let standardHeaderStickyNavWrapper = document.querySelector('.cmp-standard-header__sticky-nav--wrapper');

        if (!standardHeader || !standardHeaderStickyNav) {
            return;
        }

        let standardHeaderTitle = standardHeader.querySelector('.cmp-standard-header .cmp-title__text');
        let standardHeaderPrimaryButton = standardHeader.querySelector('.cmp-standard-header .cmp-standard-header__button--primary');
        let standardHeaderSecondaryButton = standardHeader.querySelector('.cmp-standard-header .cmp-standard-header__button--secondary');
        let standardHeaderToParentLink = standardHeader.querySelector('.cmp-standard-header .cmp-to-parent-link');

        let standardHeaderStickyNavTitle = standardHeaderStickyNav.querySelector('.cmp-standard-header__sticky-nav__title');
        let standardHeaderStickyNavPrimaryButton = standardHeaderStickyNav.querySelector('.cmp-standard-header__sticky-nav__button--primary');
        let standardHeaderStickyNavSecondaryButton = standardHeaderStickyNav.querySelector('.cmp-standard-header__sticky-nav__button--secondary');
        let standardHeaderStickyNavToParentLink = standardHeaderStickyNav.querySelector('.cmp-standard-header__sticky-nav__to-parent-link');

        let isStyleCold = document.querySelector('.cmp-standard-header .cmp-standard-header__button--primary')?.classList.contains('has-button--style-cold');
        if (isStyleCold) {
            standardHeaderStickyNav.classList.add('cmp-standard-header__sticky-nav--style-cold');
        }

        let isStyleWarm = document.querySelector('.cmp-standard-header .cmp-standard-header__button--primary')?.classList.contains('has-button--style-warm');
        if (isStyleWarm) {
            standardHeaderStickyNav.classList.add('cmp-standard-header__sticky-nav--style-warm');
        }

        if (standardHeaderStickyNavTitle) {
            standardHeaderStickyNavTitle.innerHTML = standardHeaderTitle.outerHTML;
        }

        if (standardHeaderPrimaryButton) {
            standardHeaderStickyNavPrimaryButton.innerHTML = standardHeaderPrimaryButton.outerHTML;
        }

        if (standardHeaderSecondaryButton) {
            standardHeaderStickyNavSecondaryButton.innerHTML = standardHeaderSecondaryButton.outerHTML;
            if (isStyleWarm) {
                standardHeaderStickyNavSecondaryButton.className = 'cmp-standard-header__sticky-nav__button--secondary hide-in-mobile button has-button-link has-button-link--style-warm';
            } else {
                standardHeaderStickyNavSecondaryButton.className = 'cmp-standard-header__sticky-nav__button--secondary hide-in-mobile button has-button-link has-button-link--style-cold';
            }
        }

        if (standardHeaderToParentLink) {
            standardHeaderStickyNavToParentLink.innerHTML = standardHeaderToParentLink.outerHTML;
        }

        initializeDatalayerByContext(
            context = standardHeader,
            removeExistingDatalayerElements = false,
            addNewDataLayerElements = true,
            contextParentElement = undefined
        );

        let options = {
            root: null,
            rootMargin: '0px',
            threshold: [0]
        };
    
        let handleStickyNav = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    standardHeaderStickyNavWrapper.classList.remove('cmp-standard-header__sticky-nav--wrapper--show');
                } else {
                    standardHeaderStickyNavWrapper.classList.add('cmp-standard-header__sticky-nav--wrapper--show');
                }
            });
        };
    
        let observer = new IntersectionObserver(handleStickyNav, options);
    
        observer.observe(standardHeader);

        jsB2c.buttons.init()
    
    }


    if (document.readyState !== 'loading') {
        init();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }

})();