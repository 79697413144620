import { ProductSeriesCompareFlyout } from '/src/components/b2c/product-series-list/product-series-compare-flyout';

(function () {

    'use strict';

    const initializeProductSeriesCards = () => {
        const productSeriesCards = document.querySelectorAll('.js-product-series-card');

        if (productSeriesCards.length) {
            document.addEventListener('productSeriesListVariantChange', (event) => {
                const targetProductSeriesCard = document.querySelector('.js-product-series-card[data-product-series-id="' + event.detail.product + '"');
                //Update image Variant
                const activeVariant = targetProductSeriesCard.querySelector('[data-active-variant]');
                activeVariant.removeAttribute('data-active-variant');
                const targetVariant = targetProductSeriesCard.querySelector('.js-product-series-card-visual[data-variant-id="' + event.detail.variant + '"]');
                targetVariant.setAttribute('data-active-variant', 'true');

                const linkElement = targetProductSeriesCard.querySelector('a');
                const link = targetProductSeriesCard.dataset.productSeriesLink;
                linkElement.href = link.replace('.html', '') + '.' + event.detail.variant + '.html';
            });

            document.addEventListener('change', (event) => {
                if (event.target.classList.contains('js-product-series-card-compare-input')) {
                    const productSeriesList = event.target.closest('.js-product-series-list');
                    const compareFlyout = productSeriesList.querySelector('.js-product-series-compare-flyout');

                    // Get necessary data to emit
                    const changeTrigger = event.target.id;
                    const productSeriesItem = event.target.closest('.js-product-series-card');
                    const productSeriesID = productSeriesItem.dataset.productSeriesId;
                    const productSeriesJsonPath = productSeriesItem.dataset.productSeriesPagePathJson;
                    const productSeriesLink = productSeriesItem.dataset.productSeriesLink;
                    const productSeriesTitle = productSeriesItem.querySelector('.product-series-card__header h3').textContent;
                    const productSeriesThumb = productSeriesItem.querySelector('.product-series-card__visual[data-active-variant] .product-series-card__product-shot .cmp-image__image').src;
                    const compareAction = event.target.checked ? 'add' : 'remove';

                    const customEvent = new CustomEvent('productSeriesComparisonChange', {
                        bubbles: true,
                        cancelable: false,
                        composed: true,
                        detail: {
                            'trigger': changeTrigger,
                            'action': compareAction,
                            'product': productSeriesID,
                            'productJson': productSeriesJsonPath,
                            'productTitle': productSeriesTitle,
                            'productThumb': productSeriesThumb,
                            'productLink': productSeriesLink
                        }
                    });

                    compareFlyout.dispatchEvent(customEvent);
                }
            });

            document.addEventListener('productSeriesComparisonRenderUpdate', (event) => {
                const productOneTitle = document.querySelector('.js-product-series-comparison-product-one-title');
                const productTwoTitle = document.querySelector('.js-product-series-comparison-product-two-title');
                productOneTitle.innerText = `${event.detail['product-one-title']}`;
                productTwoTitle.innerText = `${event.detail['product-two-title']}`;
            });
        }
    };

    const clearProductSeriesCompareCheckboxes = () => {
        const compareCheckboxes = document.querySelectorAll('.js-product-series-card-compare-input');
        [...compareCheckboxes].forEach(compareCheckbox => {
            compareCheckbox.checked = false;
        });
    };

    const initializeProductSeriesCompare = () => {
        const compareFlyout = document.querySelector('.js-product-series-compare-flyout');
        if (compareFlyout) {
            new ProductSeriesCompareFlyout(compareFlyout);

            // Make sure compare inputs are unchecked when browser back button is used
            // Browsers that support is persistent state (Safari) will save the state on browser back button
            window.addEventListener('pageshow', () => {
                const navType = window.performance.getEntriesByType('navigation')[0].type;
                if (navType === 'back_forward') {
                    clearProductSeriesCompareCheckboxes();
                }
            });
        }
    };

    if (document.readyState !== 'loading') {
        initializeProductSeriesCards();
        initializeProductSeriesCompare();
    } else {
        document.addEventListener('DOMContentLoaded', initializeProductSeriesCards);
        document.addEventListener('DOMContentLoaded', initializeProductSeriesCompare);
    }

})();
